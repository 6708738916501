import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
} from "@mui/material";
import Icon from "./vectors/bundle";
import GlobalStyle from "./styles/global";
import LogoContainer from "./partials/logo";

const MainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
}));

const TopBar = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    height: "60px",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    zIndex: 1000,
    background: "url('/assets/bg-chip.png') repeat-x",
}));

const RightComponents = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "20px",
});

const SideMenu = styled(Box)(({ isCollapsed }) => ({
    position: "fixed",
    left: 0,
    top: "60px",
    bottom: 0,
    width: isCollapsed ? "60px" : "240px",
    backgroundColor: "#ffffff",
    boxShadow: "2px 0 4px rgba(0,0,0,0.1)",
    transition: "width 0.3s ease",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
    zIndex: 2,
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "3px",
        transition: "background 0.2s ease",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
    ...(isCollapsed && {
        "&::-webkit-scrollbar": {
            width: "2px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "transparent",
        },
        "&:hover::-webkit-scrollbar-thumb": {
            background: "#888",
        },
    }),
}));

const Content = styled(Box)(({ isCollapsed }) => ({
    marginLeft: isCollapsed ? "60px" : "240px",
    padding: "20px",
    transition: "all 0.3s ease",
    width: `calc(100% - ${isCollapsed ? "60px" : "240px"})`,
    height: "calc(100vh - 60px)",
    backgroundColor: "#ffffff",
    backgroundImage: "url('/assets/bg-waving.png')",
    backgroundSize: "cover",
    overflowX: "hidden",
    overflowY: "auto",
    position: "fixed",
    top: "60px",
    right: 0,
    bottom: 0,
    zIndex: 1,
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "3px",
        transition: "background 0.2s ease",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
}));

const MenuListItem = styled(ListItem)(({ theme, isCollapsed }) => ({
    padding: "12px 16px",
    display: "flex",
    justifyContent: isCollapsed ? "center" : "flex-start",
    alignItems: "center",
    position: "relative",
    height: "45px",
    "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        "& .MuiListItemText-primary": {
            fontWeight: "bold",
        },
        "& .chevron-icon": {
            opacity: 1,
            right: "16px",
        },
    },
    "&:focus": {
        outline: "none",
        borderRadius: "8px",
    },
}));

const MenuItemChevronIcon = styled(Box)(({ theme }) => ({
    position: "absolute",
    right: "-20px",
    opacity: 0,
    transition: "opacity 0.2s ease, right 0.2s ease",
}));

const MenuItemText = styled(ListItemText)(({ isvisible }) => ({
    display: isvisible ? "initial" : "none",
    opacity: isvisible ? 1 : 0,
    transition: "opacity 1s ease",
    transitionDelay: "0.3s",
}));

const menuItems = [
    {
        icon: <Icon name="chartBar" size={20} />,
        text: "Kiến thức",
        path: "/knowledge",
    },
    {
        icon: <Icon name="globePointer" size={20} />,
        text: "Vĩ mô",
        path: "/economicdata",
    },
    {
        icon: <Icon name="fileContent" size={20} />,
        text: "Tin tức",
        path: "/news",
    },
    {
        icon: <Icon name="windowPointer" size={20} />,
        text: "Video Player",
        path: "/videoplayer",
    },
    {
        icon: <Icon name="fileContent" size={20} />,
        text: "Lịch",
        path: "/calendar",
    },
];

const Layout = ({ children }) => {
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isAnimationComplete, setIsAnimationComplete] = useState(true);

    const toggleMenu = () => {
        setIsCollapsed(!isCollapsed);
        setIsAnimationComplete(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => setIsAnimationComplete(true), 300);
        return () => clearTimeout(timer);
    }, [isCollapsed]);

    const handleMenuItemClick = (path) => {
        navigate(path);
    };

    return (
        <MainContainer>
            {GlobalStyle}

            <TopBar>
                <LogoContainer name="Nguyễn Quang Anh" />
                <RightComponents>
                    {/* <IconButton>
                        <Icon name="leftChevron" size={20} />
                    </IconButton>
                    <IconButton>
                        <Icon name="rightChevron" size={20} />
                    </IconButton> */}
                </RightComponents>
            </TopBar>

            <SideMenu isCollapsed={isCollapsed}>
                <List sx={{ flex: 1, padding: "8px" }}>
                    {menuItems.map((item, index) => (
                        <MenuListItem
                            key={index}
                            tabIndex={0}
                            isCollapsed={isCollapsed}
                            onClick={() => handleMenuItemClick(item.path)}
                        >
                            <ListItemIcon
                                sx={{ minWidth: isCollapsed ? "auto" : "40px" }}
                            >
                                {item.icon}
                            </ListItemIcon>

                            {!isCollapsed && (
                                <>
                                    <MenuItemText
                                        primary={item.text}
                                        isvisible={(
                                            !isCollapsed && isAnimationComplete
                                        ).toString()}
                                    />
                                    <MenuItemChevronIcon className="chevron-icon">
                                        <Icon name="rightChevron" />
                                    </MenuItemChevronIcon>
                                </>
                            )}
                        </MenuListItem>
                    ))}
                </List>

                <IconButton
                    onClick={toggleMenu}
                    sx={{
                        position: "fixed",
                        bottom: "20px",
                        left: isCollapsed ? "45px" : "222px",
                        transition: "left 0.3s ease",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        "&:hover": {
                            backgroundColor: "#f5f5f5",
                        },
                    }}
                    aria-label={isCollapsed ? "Expand menu" : "Collapse menu"}
                >
                    {isCollapsed ? (
                        <Icon name="rightChevron" />
                    ) : (
                        <Icon name="leftChevron" />
                    )}
                </IconButton>
            </SideMenu>

            <Content isCollapsed={isCollapsed}>{children}</Content>
        </MainContainer>
    );
};

export default Layout;
