import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, Link } from "@mui/material";
import Icon from "../theme/vectors/bundle";
import LayoutAuth from "../theme/layout-auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Page = () => {
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [step, setStep] = useState(1);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [deviceId, setDeviceId] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    }, [email]);

    const handleRequestCode = async () => {
        try {
            const response = await axios.post("/api/auth/request-code", {
                email,
                userAgent: navigator.userAgent,
                deviceId: deviceId || undefined,
            });
            if (response.data.success) {
                setDeviceId(response.data.deviceId);
                setStep(2);
                setError("");
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError("Đã có lỗi xảy ra, xin vui lòng thử lại.");
        }
    };

    const handleVerifyCode = async () => {
        try {
            const response = await axios.post("/api/auth/verify-code", {
                email,
                code,
                deviceId,
            });
            if (response.data.success) {
                // Store tokens and navigate to home page
                localStorage.setItem("accessToken", response.data.accessToken);
                localStorage.setItem("refreshToken", response.data.refreshToken);
                navigate("/");
            } else {
                setError("Đăng nhập thất bại");
            }
        } catch (error) {
            setError("Đã có lỗi xảy ra, xin vui lòng thử lại.");
        }
    };

    const handleResendCode = () => {
        setStep(2);
        handleRequestCode();
    };

    const handleTryAnotherEmail = () => {
        setStep(1);
        setEmail("");
        setCode("");
        setError("");
    };

    return (
        <LayoutAuth>
            <Box sx={{ width: "100%", maxWidth: 400, margin: "auto" }}>
                <h1>Đăng nhập</h1>
                {step === 1 && (
                    <>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            margin="normal"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleRequestCode}
                            disabled={!isEmailValid}
                            sx={{ mt: 2 }}
                        >
                            Nhận mã xác thực
                        </Button>
                    </>
                )}
                {step === 2 && (
                    <>
                        <TextField
                            fullWidth
                            label="Mã xác thực"
                            variant="outlined"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            margin="normal"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleVerifyCode}
                            disabled={code.length < 5}
                            sx={{ mt: 2 }}
                        >
                            Đăng nhập
                        </Button>
                        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                            <Link href="#" onClick={handleResendCode}>
                                Gửi lại mã
                            </Link>
                        </Box>
                    </>
                )}
                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
                {error && (
                    <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                        <Link href="#" onClick={handleTryAnotherEmail}>
                            Thử lại với Email khác
                        </Link>
                        <Link href="#" onClick={handleResendCode}>
                            Gửi lại mã
                        </Link>
                    </Box>
                )}
            </Box>
        </LayoutAuth>
    );
};

export default Page;