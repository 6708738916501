import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import Layout from "../theme/layout";
import Icon from "../theme/vectors/bundle";
import moment from "moment";
import "moment-lunar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import timeGridPlugin from "@fullcalendar/timegrid";

const ANNUALLY_EVENTS_SRC = "https://storage.nguyenquanganh.net/annually-event-data.json";

const Page = () => {
    const [events, setEvents] = useState([]);
    const [annuallyEventsData, setAnnuallyEventsData] = useState([]);
    const calendarRef = useRef(null);

    useEffect(() => {
        const fetchAnnuallyEventsData = async () => {
            try {
                const response = await fetch( ANNUALLY_EVENTS_SRC );
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setAnnuallyEventsData(data);
            } catch (error) {
                console.error('Error fetching annually events data:', error);
            }
        };

        fetchAnnuallyEventsData();
    }, []);

    useEffect(() => {
        if (annuallyEventsData.length > 0) {
            const processedEvents = processEvents(annuallyEventsData);
            setEvents(processedEvents);
        }
    }, [annuallyEventsData, calendarRef.current?.getApi().getDate()]);

    const processEvents = (rawEvents) => {
        const processedEvents = [];

        rawEvents.forEach((event) => {
            const [year, month, day] = event.start.split("-").map(Number);

            let newDate;

            // Get the current year from the calendar
            const prevYear = calendarRef.current.getApi().getDate().getFullYear() - 1;
            const currentYear = calendarRef.current.getApi().getDate().getFullYear();
            
            // Generate event for the current year
            if (event.type === "lunar") {
                // Create a lunar date with the current year
                const lunarDate = moment()
                    .year(currentYear)
                    .month(month - 1)
                    .date(day);
                
                // Convert lunar to solar
                newDate = lunarDate.solar().toDate();

                let newDate2;
                // Create a lunar date with the previous year
                const prevYearLunarDate = moment()
                    .year(prevYear)
                    .month(month - 1)
                    .date(day);
                
                // Convert lunar to solar
                newDate2 = prevYearLunarDate.solar().toDate();

                processedEvents.push({
                    ...event,
                    start: moment(newDate2).format("YYYY-MM-DD"),
                    allDay: true,
                });

            } else {
                newDate = moment()
                    .year(currentYear)
                    .month(month - 1)
                    .date(day)
                    .toDate();
            }
            
            processedEvents.push({
                ...event,
                start: moment(newDate).format("YYYY-MM-DD"),
                allDay: true,
            });
        });

        return processedEvents;
    };

    const renderDayCellContent = (arg) => {
        const solarDate = arg.date.getDate();
        const lunarDate =
            moment(arg.date).lunar().format("D") == 1
                ? moment(arg.date).lunar().format("D/M")
                : moment(arg.date).lunar().format("D");
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <Box sx={{ fontWeight: "bold" }}>{solarDate}</Box>
                <Box sx={{ color: "gray" }}>{lunarDate}</Box>
            </Box>
        );
    };

    return (
        <Layout>
            <Box sx={{ height: "80vh", padding: 2 }}>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    initialView="dayGridMonth"
                    initialDate={new Date()}
                    events={events}
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    locale="vi"
                    locales={allLocales}
                    timeZone="Asia/Ho_Chi_Minh"
                    buttonText={{
                        today: "Hôm nay",
                        month: "Tháng",
                        week: "Tuần",
                        day: "Ngày",
                    }}
                    dayCellContent={renderDayCellContent}
                    
                />
            </Box>
        </Layout>
    );
};

export default Page;
