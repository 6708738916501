import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, Box } from '@mui/material';
import Layout from "../theme/layout";
import Icon from "../theme/vectors/bundle";

const Page = () => {
    const [videoUrl, setVideoUrl] = useState("");
    const [videoName, setVideoName] = useState("");
    const [videoKey, setVideoKey] = useState(0);
    const inputRef = useRef(null);
    const videoRef = useRef(null);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [currentChunk, setCurrentChunk] = useState(0);
    const previewTimeoutRef = useRef(null);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const previewDuration = 8; // Preview duration in seconds
    const [maxChunks, setMaxChunks] = useState(1);

    const cleanFbcdnUrl = (url) => {
        if (url.includes('fbcdn.net') || url.includes('cdninstagram.com')) {
            const urlObj = new URL(url);
            urlObj.searchParams.delete('bytestart');
            urlObj.searchParams.delete('byteend');
            return urlObj.toString();
        }
        return url;
    };

    const handleCopyURL = () => {
        navigator.clipboard.writeText(videoUrl);
    };

    const handlePasteURL = async () => {
        try {
            let text = await navigator.clipboard.readText();
            text = cleanFbcdnUrl(text);
            setVideoUrl(text);
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    };
    
    const handleCopyName = () => {
        navigator.clipboard.writeText(videoName);
    };

    const handlePasteName = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setVideoName(text);
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    };

    useEffect(() => {
        setVideoKey(prevKey => prevKey + 1);
        setIsVideoReady(false);
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [videoUrl]);

    const togglePreview = () => {
        if (isPreviewMode) {
            stopPreview();
        } else {
            startPreview();
        }
    };

    const startPreview = () => {
        setIsPreviewMode(true);
        setCurrentChunk(0);
        playNextChunk();
    };

    const stopPreview = () => {
        setIsPreviewMode(false);
        setCurrentChunk(0);
        if (videoRef.current) {
            videoRef.current.pause();
        }
        if (previewTimeoutRef.current) {
            clearTimeout(previewTimeoutRef.current);
        }
    };

    const playNextChunk = () => {
        if (!videoRef.current) {
            stopPreview();
            return;
        }

        const duration = videoRef.current.duration;
        const chunkDuration = Math.min(duration / 11, 360); // 360 seconds = 6 minutes
        setMaxChunks(Math.ceil(duration / chunkDuration));

        if (currentChunk >= maxChunks) {
            stopPreview();
            return;
        }
        
        const startTime = currentChunk * chunkDuration;
        const randomStart = startTime + Math.random() * (chunkDuration - previewDuration);
        const clampedStart = Math.min(randomStart, duration - previewDuration);

        videoRef.current.currentTime = clampedStart;
        videoRef.current.play();

        previewTimeoutRef.current = setTimeout(() => {
            setCurrentChunk(prevChunk => prevChunk + 1);
        }, previewDuration * 1000);
    };

    useEffect(() => {
        if (isPreviewMode && currentChunk < maxChunks) {
            playNextChunk();
        }
    }, [isPreviewMode, currentChunk, maxChunks]);

    const handleDownload = () => {
        if (videoUrl) {
            const link = document.createElement('a');
            link.href = videoUrl;
            link.download = videoName + '.mp4'; // You can set a default name or extract it from the URL
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleVideoReady = () => {
        setIsVideoReady(true);
    };

    const handleVideoInteraction = () => {
        if (isPreviewMode) {
            stopPreview();
        }
    };

    return (
        <Layout>
            <h1>Video Player</h1>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                    fullWidth
                    label="Video Source URL"
                    variant="outlined"
                    value={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    inputRef={inputRef}
                />
                <Button onClick={handleCopyURL} sx={{ ml: 1 }}>
                    Copy
                </Button>
                <Button onClick={handlePasteURL} sx={{ ml: 1 }}>
                    Paste
                </Button>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                    fullWidth
                    label="Video Name"
                    variant="outlined"
                    value={videoName}
                    onChange={(e) => setVideoName(e.target.value)}
                    inputRef={inputRef}
                />
                <Button onClick={handleCopyName} sx={{ ml: 1 }}>
                    Copy
                </Button>
                <Button onClick={handlePasteName} sx={{ ml: 1 }}>
                    Paste
                </Button>
            </Box>

            <Box sx={{ mb: 2 }}>
                <Button 
                    variant="contained" 
                    onClick={togglePreview} 
                    disabled={!videoUrl || !isVideoReady}
                    sx={{ mr: 2 }}
                >
                    {isPreviewMode ? 'Stop Preview' : 'Preview'}
                </Button>
                <Button 
                    variant="contained" 
                    onClick={handleDownload} 
                    disabled={!videoUrl || !isVideoReady}
                >
                    Download
                </Button>
            </Box>

            <video 
                width="100%" 
                controls 
                key={videoKey} 
                ref={videoRef}
                onLoadedMetadata={handleVideoReady}
                onPause={handleVideoInteraction}
            >
                <source src={videoUrl} />
                Your browser does not support the video tag.
            </video>

        </Layout>
    );
};

export default Page;