import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
} from "@mui/material";
import Icon from "./vectors/bundle";
import GlobalStyle from "./styles/global";
import LogoContainer from "./partials/logo";

const MainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
}));

const TopBar = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    height: "60px",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    zIndex: 1000,
    background: "url('/assets/bg-chip.png') repeat-x",
}));

const RightComponents = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "20px",
});

const Content = styled(Box)(({ isCollapsed }) => ({
    padding: "20px",
    transition: "all 0.3s ease",
    width: "100%",
    height: "calc(100vh - 60px)",
    backgroundColor: "#ffffff",
    backgroundImage: "url('/assets/bg-waving.png')",
    backgroundSize: "cover",
    overflowX: "hidden",
    overflowY: "auto",
    position: "fixed",
    top: "60px",
    right: 0,
    bottom: 0,
    zIndex: 1,
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "3px",
        transition: "background 0.2s ease",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
    },
}));

const Layout = ({ children }) => {
    const navigate = useNavigate();

    return (
        <MainContainer>
            {GlobalStyle}

            <TopBar>
                <LogoContainer name="Nguyễn Quang Anh" />
                <RightComponents>
                    {/* <IconButton>
                        <Icon name="leftChevron" size={20} />
                    </IconButton>
                    <IconButton>
                        <Icon name="rightChevron" size={20} />
                    </IconButton> */}
                </RightComponents>
            </TopBar>

            <Content>{children}</Content>
        </MainContainer>
    );
};

export default Layout;
